<template>
    <div>
        <v-container grid-list-md text-xs-center fluid>
            <h1>Pendências Financeiras</h1>
            <v-divider class="mb-4"></v-divider>

            <FilterDateClientStore hideDate hideClient @changeStore="changeStore" />

            <v-layout wrap class="my-12" v-if="items.length > 0">
                <v-flex xs12>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                R$ {{ total(items) }}
                            </div>
                            Total
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
            <v-divider class="mb-4"></v-divider>
        </v-container>

        <SimpleTable v-if="items.length > 0" endpoint="payments/pendency" :items="items" @needReload="search" :aditionalColumns="headers" inlineToggle hideEdit hideDelete hideNew
            hideIdColumn>
            <template v-slot:extra-column="item">
                <v-chip class="ma-0" outlined color="success" v-if="item.createdAt"> {{ $moment(item.createdAt).format("DD/MM/YYYY") }} </v-chip>
                <v-chip class="ma-0" outlined color="error"> R$ {{ Number.parseFloat(item.left).toFixed(2) }} </v-chip>
            </template>
            <template v-slot:more-btn="item">
                <v-btn class="ma-2" color="green" dark small @click="payment(item)"> <v-icon dark small left>money</v-icon>Pagar </v-btn>
            </template>
        </SimpleTable>
    </div>
</template>

<script>
import FilterDateClientStore from "@/components/shared/FilterDateClientStore";
import SimpleTable from "@/components/core/SimpleTable";

export default {
    components: { FilterDateClientStore, SimpleTable },

    data() {
        return {
            items: [],
            dates: [],
            stores: [],
            selectedStore: null,
            headers: [
                { text: this.$t(""), value: "more-btn", align: "right", sortable: true },
                { text: this.$t("Falta Pagar"), value: "extra-column", align: "right", sortable: true },
                { text: this.$t("Cliente"), value: "client", align: "right", sortable: true },
                { text: this.$t("Unidade"), value: "store", sortable: true },
                { text: this.$t("Atendimento"), value: "id", align: "right", sortable: true },
            ],
        };
    },

    methods: {
        changeStore(event) {
            this.selectedStore = event;
            this.search();
        },
        search() {
            let query = this.selectedStore ? `?store=${this.selectedStore.id}` : "";

            this.$http
                .get(`payments/pendency${query}`)
                .then((result) => (this.items = result))
                .catch(() => (this.items = []));
        },
        payment(item) {
            this.$router.push({ path: `/payments/${item.id}` });
        },
        total(items) {
            return Number.parseFloat(items.reduce((sum, i) => sum + parseFloat(i.left), 0)).toFixed(2);
        },
    },
};
</script>